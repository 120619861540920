<template lang="pug">
  div.wrap-item-meeting-member.f.fm.flex-between.py6
    div.profile.f.fm
      div.mr6
        AtomUserIconByEmail(v-if="attendee.email"
          :email="attendee.email" :size="38")
        //- v-icon(v-else size="38px") mdi-account-circle
      div.pt1
        span.fz14.block {{attendee.name}}
        a(v-if="attendee.email"
          :href="emailHref").email.fz12.block {{attendee.email}}
        a(v-if="attendee.telNo"
          :href="telNoHref").tel-no.fz12.block {{attendee.telNo}}
        //- span(v-if="attendee.email").email.fz12.block {{attendee.email}}
        //- span(v-if="attendee.telNo").tel-no.fz12.block {{attendee.telNo}}
    div(v-if="policyRequiresAdjustment").wrap-status
      span(:style="`color: ${statusColor};`").fz11 {{statusText}}
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-item-meeting-member {
  border-bottom: solid $border_size $border_color;
  .profile {
    .email,
    .tel-no {
      color: $secondary_text_color;
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>

<script>
import { domain } from '@/components/utils/config'
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import AtomUserIconByEmail from '@/components/atom/AtomUserIconByEmail'

export default {
  components: {
    AtomUserIconByEmail
  },
  props: {
    meeting: {
      type: Object,
      required: true
    },
    attendee: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isInitialized: false,
      atenndeeEvents: [],
      statusText: '',
      statusColor: ''
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    policyRequiresAdjustment() {
      return (
        ['01-unanimous'].includes(this.meeting.adjustmentPolicy) ||
        this.meeting.adjustmentPolicy === undefined
      ) // for old version
    },
    invitationURL() {
      return `${domain}/invitation/lp/${this.meeting.id}`
    },
    footerText() {
      return (
        '%0D%0A-----------------%0D%0A' +
        'Meet Awesome ~ 人生を豊かにする出会いをもっと%0D%0A' +
        'https://www.meetawesome.jp/%0D%0A' +
        '* MeetAwesomeはGoogleカレンダーより自動的にスケジュール調整を行うサービスです。%0D%0A' +
        '提供：Truffle Technologies株式会社'
      )
    },
    emailHref() {
      const subject = `ミーティングの日程調整「${this.meeting.title}」`
      const body =
        `${this.attendee.name}様` +
        '%0D%0A%0D%0A%0D%0A' +
        `招待URLから日程を送信：${this.invitationURL}` +
        this.footerText
      return `mailto:${this.attendee.email}?subject="${subject}&body=${body}`
    },
    telNoHref() {
      const subject = `ミーティングの日程調整「${this.meeting.title}」`
      const body =
        `${this.attendee.name}様` +
        '%0D%0A%0D%0A%0D%0A' +
        `招待URLから日程を送信：${this.invitationURL}` +
        '%0D%0A%0D%0A%0D%0A' +
        this.footerText
      return `mailto:${this.attendee.telNo}?subject="${subject}&body=${body}`
    }
  },
  async created() {
    // this.attendeeEvents = await database.meetingCollection()
    //   .getAttendeeEventsByEmail(this.$route.params.meetingId, this.attendee.email)

    // if (this.attendeeEvents.empty) {
    //   this.statusText = '日程未送信'
    //   this.statusColor = '#ff5252'
    // } else {
    //   this.statusText = '日程送信済'
    //   this.statusColor = '#999'
    // }

    database
      .meetingCollection()
      .getAttendeeEventsByEmailRealTime(
        this.$route.params.meetingId,
        this.attendee.email,
        this.updateAttendeeEvents
      )
  },
  methods: {
    updateAttendeeEvents(q) {
      this.atenndeeEvents = q.docs.map((d) => d.data())[0]
      if (!this.atenndeeEvents) {
        this.statusText = '日程未送信'
        this.statusColor = '#ff5252'
      } else {
        this.statusText = '日程送信済'
        this.statusColor = '#999'
        // 初期化済ということはミーティングが入力されたということ
        if (this.isInitialized) {
          const attendee = this.meeting.attendee.filter(
            (e) => e.email === this.atenndeeEvents.email
          )[0]
          this.$emit('setNotification', {
            label: `✌️ ${attendee.name}が日時の入力を完了しました！`,
            class: 'sent-events'
          })
        }
      }

      this.isInitialized = true
    }
  }
}
</script>
