<template lang="pug">
  div.wrap-copy-invitation-url
    div(v-if="shareSheetIsUsable"
      @click="onCopyByShareSheet").wrap-copy-btn.f.fm.px12.py12
      v-icon(dark).mr8 mdi-share-variant
      span.text-center 招待リンクをシェアする
    div(v-else
      v-clipboard:copy="invitationText"
      v-clipboard:success="onCopyLink").wrap-copy-btn.f.fm.px12.py12
      v-icon(dark).mr8 mdi-share-variant
      span.text-center 招待リンクをシェアする
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-copy-invitation-url {
  .wrap-copy-btn {
    width: 100%;
    max-width: 400px;
    border-radius: 3px;
    background: $active_color;
    color: $dark_primary_text_color;
    span {
      width: 100%;
    }
  }
}
</style>

<script>
import { domain } from '@/components/utils/config'

export default {
  props: {
    meeting: {
      type: Object,
      required: true
    },
    isModalBtn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    invitationURL() {
      return `${domain}/invitation/lp/${this.meeting.id}`
    },
    invitationText() {
      return (
        `${this.meeting.title}への招待リンク:\n` +
        `${this.invitationURL}\n\n` +
        '日時を送信して日程調整を行いましょう！'
      )
    },
    shareSheetIsUsable() {
      return navigator.share ? true : false
    }
  },
  methods: {
    onCopyLink() {
      const msgForTracking = this.isModalBtn ? 
        'click_share_button_in_modal' : 'click_share_button_in_page'
      // eslint-disable-next-line
      this.$root.$emit('sendTrackingEventByClick', { 
        message: msgForTracking,
        option: { meetingId: this.meeting.id }
      })

      alert('招待URLをコピーしました')
    },
    onCopyByShareSheet() {
      const msgForTracking = this.isModalBtn ? 
        'click_share_button_in_modal' : 'click_share_button_in_page'
      // eslint-disable-next-line
      this.$root.$emit('sendTrackingEventByClick', { 
        message: msgForTracking,
        option: { meetingId: this.meeting.id }
      })

      navigator
        .share({
          title: '招待URLをシェア',
          text: this.invitationText,
          // text: `日程調整に参加してもらうために招待URLをシェアしましょう！`,
          url: this.invitationURL
        })
        .then(() => {
          console.log('Successful share')
        })
        .catch((error) => {
          console.log('Error sharing', error)
        })
    }
  }
}
</script>
