import { render, staticRenderFns } from "./ItemCopyInvitationURL.vue?vue&type=template&id=6e514c00&scoped=true&lang=pug&"
import script from "./ItemCopyInvitationURL.vue?vue&type=script&lang=js&"
export * from "./ItemCopyInvitationURL.vue?vue&type=script&lang=js&"
import style0 from "./ItemCopyInvitationURL.vue?vue&type=style&index=0&id=6e514c00&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e514c00",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
