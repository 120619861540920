import { format, differenceInHours, differenceInMinutes, subHours } from 'date-fns'
import { stringify } from 'qs'
import * as ics from 'ics'

// ref: https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs

export function generateAddToGoogleCalendarUrl(meeting) {
  const { adjustedDate, title, about, attendee } = meeting
  if (!adjustedDate) {
    return null
  }
  // eslint-disable-next-line prettier/prettier
  const dateFormatStr = 'yyyyMMdd\'T\'HHmmss'
  const fromText = format(adjustedDate.start.toDate(), dateFormatStr, { timeZone: 'UTC' })
  const toText = format(adjustedDate.end.toDate(), dateFormatStr, { timeZone: 'UTC' })
  const attendeeContacts = attendee.map((a) => {
    if (a.email) return a.email
    if (a.telNo) return a.telNo
    return a.name
  })
  const addText = attendeeContacts.join(',')

  const googleQuery = stringify({
    action: 'TEMPLATE',
    text: title,
    details: about,
    dates: `${fromText}/${toText}`,
    add: addText
  })

  return `https://calendar.google.com/calendar/render?${googleQuery}`
}

export function generateAddToYahooCalendarUrl(meeting) {
  const { adjustedDate, title, about, attendee } = meeting
  if (!adjustedDate) {
    return null
  }
  const startDate = adjustedDate.start.toDate()
  const endDate = adjustedDate.end.toDate()
  // eslint-disable-next-line prettier/prettier
  const dateFormatStr = 'yyyyMMdd\'T\'HHmmssX'
  const fromText = format(startDate, dateFormatStr, { timeZone: 'UTC' })
  const duration = `${(differenceInHours(endDate, startDate) + '').padStart(2, '0')}${(
    (differenceInMinutes(endDate, startDate) % 60) +
    ''
  ).padStart(2, '0')}`
  // Yahoo Calendarはemailでないと参加者に追加できない
  const attendeeContacts = attendee
    .filter((a) => a.email)
    .map((a) => {
      if (a.email) return a.email
    })
  const invListText = attendeeContacts.join(',')

  const yahooQuery = stringify({
    v: 60,
    view: 'd',
    type: '20',
    title,
    st: fromText,
    desc: about,
    dur: duration,
    inv_list: invListText,
    rem1: '1H'
  })

  return `https://calendar.yahoo.com/?${yahooQuery}`
}

export function generateAddToOutlookCalendarUrl(meeting) {
  // e.g. https://outlook.live.com/calendar/0/deeplink/compose?path=/calendar/action/compose&rru=addevent&startdt=2020-12-31T19:30:00Z&enddt=2020-12-31T22:30:00Z&subject=Birthday&body=With%20clowns%20and%20stuff&location=North%20Pole
  const { adjustedDate, title, about, attendee } = meeting
  if (!adjustedDate) {
    return null
  }

  // MEMO: example通りにやっても2021-12-10T16:00:00Z → 12/11 1:00 2020-12-31T22:30:00Z → 12/11 7:30にズレる...
  // ひとまずは9時間のオフセットを設けて実行する
  // eslint-disable-next-line prettier/prettier
  const dateFormatStr = 'yyyy-MM-dd\'T\'HH:mm:ss\'Z\'' // 2020-12-31T22:30:00Z
  const startDateWithOffset = subHours(adjustedDate.start.toDate(), 9)
  const endDateWithOffset = subHours(adjustedDate.end.toDate(), 9)
  const startdt = format(startDateWithOffset, dateFormatStr, { timeZone: 'UTC' })
  const enddt = format(endDateWithOffset, dateFormatStr, { timeZone: 'UTC' })

  const subject = title
  const body = about

  const attendeeContacts = attendee.map((a) => {
    if (a.email) return a.email
    if (a.telNo) return a.telNo
    return a.name
  })
  const to = attendeeContacts.join(',')

  const outlookQuery = stringify({
    path: '/calendar/action/compose',
    rru: 'addevent',
    startdt,
    enddt,
    subject,
    body,
    to,
    online: 1 // any value means true
  })

  return `https://outlook.live.com/calendar/0/deeplink/compose?${outlookQuery}`
  // for Office 365
  // return `https://outlook.office.com/calendar/0/deeplink/compose?${outlookQuery}`
}

export function generateIcsFile(meeting) {
  const { adjustedDate, title, about } = meeting
  if (!adjustedDate) {
    return null
  }
  const startDate = adjustedDate.start.toDate()
  const endDate = adjustedDate.end.toDate()

  const event = {
    start: [
      startDate.getFullYear(),
      startDate.getMonth() + 1,
      startDate.getDate(),
      startDate.getHours(),
      startDate.getMinutes()
    ],
    duration: {
      hours: differenceInHours(endDate, startDate),
      minutes: differenceInMinutes(endDate, startDate) % 60
    },
    title,
    description: about,
    categories: ['Meetings'],
    status: 'CONFIRMED',
    busyStatus: 'BUSY'
  }
  const { value } = ics.createEvent(event)
  return value
}

export function generateIcsUrl(meeting) {
  const icsContent = generateIcsFile(meeting)
  const data = new Blob([icsContent], { type: 'text/calendar' })
  return window.URL.createObjectURL(data)
}
