function getOnlineMeetingJoinUrl(meeting) {
  if (meeting.selectMeetinRoomType) {
    const type = meeting.selectMeetinRoomType
    if ('Google Meet' === type && meeting.googleMeetURL) {
      return meeting.googleMeetURL
    } else if ('Microsoft Teams' === type && meeting.microsoftMeeting?.joinWebUrl) {
      return meeting.microsoftMeeting.joinWebUrl
    } else if ('Microsoft Teams' === type && meeting.microsoftMeeting?.onlineMeeting?.joinUrl) {
      return meeting.microsoftMeeting?.onlineMeeting?.joinUrl
    } else if ('Zoom' === type && meeting.zoomMeeting?.join_url) {
      return meeting.zoomMeeting.join_url
    } else {
      return ''
    }
  } else {
    return ''
  }
}

module.exports = {
  getOnlineMeetingJoinUrl
}
