<template lang="pug">
div.wrap-module-meeting
  //- NowLoading(:show="showNowLoading")
  transition
    div(v-if="notification"
      :class="notification.class").wrap-congraturation.f.fh
      div.congraturation-content.f.fh
        span.block.fz14.line-clamp-1 {{notification.label}}
      div(@click="notification = null").wrap-close.cursor-pointer
        v-icon(size="18px" dark).m12 mdi-close
  div.meeting-content
    div.wrap-title.pt10.pb12
      div(:class="`status-${meeting.status}`").wrap-status.mb2.f.fm
        v-icon(:color="statusColor" size="18px").mr2 mdi-circle-medium
        span(:style="`color: ${statusColor};`").fz12.pt2 {{statusText}}
      span.block.fz18.bold.mb3 {{meeting.title}}
      span.block.fz14 {{meeting.about}}
    div(v-if="meeting.status == 'adjusted' || meeting.status == 'failed'").wrap-date.mt12.mb12
      span.label.fz12.mb4.block 日時
      div.f.fm.flex-between.mb24
        div
          span.block.fz14.bold {{adjustedTextStr}}
          span(v-if="meeting.status == 'adjusted' && policyRequiresAdjustment"
            ).wasted-time.fz12 {{`調整にかかった時間：${wastedTime}`}}
        div(v-if="meeting.status == 'adjusted'"
          @click="showAddToCalendar = true").btn-add-to-calendar.f.fm.cursor-pointer.py6.px8
          v-icon(size="22px" dark).mr4 mdi-calendar
          span.block.fz12 カレンダーに保存
        ModalWindow(:showModal="showAddToCalendar"
          @closeModalWindow="showAddToCalendar = false")
          div.modal-window.px40.pt24.pb12
            div(@click="showAddToCalendar = false").wrap-close-icon
              v-icon mdi-close
            span.label.fz14.block.text-center.mb16 カレンダーに保存
            a(v-for="item in addToCalendars"
              :href="item.href" target="_blank"
              :download="item.download").btn-save.f.fm.px18.py8.mb12.cursor-pointer
              img(:src="item.img" width='28px' height='28px').mr16
              span.fz14 {{item.label}}
      div(v-if="'なし' != meeting.selectMeetinRoomType && meetingUrl").wrap-video-url.pb8
        span.label.fz12.mb4.block ビデオ会議URL
        div(v-if="meetingUrl"
          v-clipboard:copy="meetingUrl"
          v-clipboard:success="onCopyVideoUrl").f.fm.flex-between
          span.line-clamp-1 {{meetingUrl}}
          v-icon mdi-content-copy
    
    div.wrap-member.py12.mb4
      div.f.flex-between
        span.label.fz12.mb8 メンバー
      ItemMeetingMember(v-for="(attendee, index) in meeting.attendee"
        :key="`$member-${index}`"
        :meeting="meeting" :attendee="attendee"
        @setNotification="setNotification")
      div.f.flex-right.mt10
        div(v-if="!memberIsSavedAsGroup"
          @click="onAddGroup").btn-add-group.f.fm.cursor-pointer
          v-icon(size="18px" :color="pallete.$active_color").mr4 mdi-account-multiple
          span.fz12 {{`${meeting.attendee.length}人のグループとして保存`}}
        div(v-else).added-group-label.f.fm.cursor-pointer
          v-icon(size="18px" :color="pallete.$deactive_color").mr4.pb2 mdi-check
          span.fz12 グループに保存済み
        ModalWindow(:showModal="showGroupNameInput"
          @closeModalWindow="showGroupNameInput = false")
          div.wrap-group-name-input.px32.py32
            div.wrap-close-icon
              v-icon(@click="showGroupNameInput = false") mdi-close
            v-text-field(label="グループ名" placeholder="グループ名を入力"
              v-model="groupName" autofocus clearable
              @focus="$event.target.select()")
            div.mb24
              span.label.block.fz14.mb4 メンバー
              span(v-for="attendee in meeting.attendee").block.mb4 {{`- ${attendee.name}`}}
            div.f.flex-right
              v-btn(@click="addGroup" width="280" color="primary") 新規グループとして保存

      div(v-if="meeting.status == 'adjusting'").mt20
        ItemCopyInvitationURL(:meeting="meeting" :isModalBtn="false")
        ModalWindow(:showModal="showRecommendShare"
          @closeModalWindow="showRecommendShare = false")
          div.wrap-recommend-share.px32.py32
            div.wrap-close-icon
              v-icon(@click="showRecommendShare = false") mdi-close
            span.block.fz14.mb6 ミーティングの設定が完了しました
            span(v-for="attendee in attendeeWithoutAnyContact").block.mb6 - {{attendee.name}}
            span.block.fz14.mb16 に招待URLをシェアしましょう！
            ItemCopyInvitationURL(:meeting="meeting" :isModalBtn="true"
              v-model="testVal")
            span.block.text-center.mt12 {{testVal}}

    //- div(v-if="policyRequiresAdjustment").mb12
      span.label.block.fz12.mb6 調整条件
      span.fz14 {{conditionText}}

    div(v-if="policyRequiresAdjustment && histories[0]").mb20
      span.label.fz12 ToDoリスト
      ItemMeetingToDo(:meeting="meeting" :histories="histories")

    //- div(v-if="policyRequiresAdjustment").wrap-history.mb20
      span.label.fz12 調整ログ
      ItemMeetingHistory(v-for="history in histories"
        :key="history.historyId" :history="history" :attendees="meeting.attendee")

    div(v-if="!uid || isAnonymous").wrap-recommend-sign-up.mt30.mb40
      span.block.fz14.block このミーティングはMeet Awesomeで作成されました。
      span.block.fz14.block.mb14 Meet Awesomeを使うと、簡単に日程調整を行うことができます。
      div.f.fc
        span(@click="onGettingStarted").cta-btn.fz14.px16.py12 Meet Awesomeを使ってみる
    //- div(v-if="!responsed").wrap-send-schedule.f.fc
      span(@click="onSend").text-center.cursor-pointer.fz14 日程を送信する
    //- div(v-if="meeting.status == 'adjusted'").wrap-request-reschedule.f.fc
      span(@click="onRequestReschedule").text-center.cursor-pointer.fz14 再調整を依頼する

</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-close {
  position: absolute;
  right: 0;
}
.wrap-module-meeting {
  width: 100%;
  min-height: 100vh;
  padding-top: $header_height;
  .wrap-congraturation {
    // width: $base_width_percent;
    // max-width: $base_max_width_px;
    height: 34px;
    margin: 0 auto;
    &.complete {
      background: $active_color;
    }
    &.sent-events {
      background: $safe_color;
    }
    &.reschedule {
      background: $dangerous_color;
    }
    span {
      color: #fff;
    }
  }
  /* アニメーション中のスタイル */
  .v-leave-active,
  .v-enter-active {
    transition: all 0.4s;
  }
  .v-enter,
  .v-leave-to {
    opacity: 0;
    height: 0;
  }
  .v-enter-to,
  .v-leave {
    opacity: 1;
    height: 34px;
  }

  .meeting-content {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
    .wrap-title {
      border-bottom: solid $border_size $border_color;
    }
    .wrap-date {
      .btn-add-to-calendar {
        color: $dark_primary_text_color;
        background: $active_color;
        border: solid 1px $active_color;
        border-radius: 3px;
      }
      .modal-window {
        position: relative;
        background: $primary_bg_color;
        border-radius: 3px;
        .wrap-close-icon {
          position: absolute;
          top: 12px;
          right: 12px;
        }
        .btn-save {
          width: 200px;
          border: $basic_border;
          border-radius: 12px;
          a,
          span {
            color: $secondary_text_color;
          }
        }
      }
    }
    .wrap-member {
      .btn-add-group {
        color: $active_color;
      }
      .added-group-labe {
        color: $deactive_color;
      }
    }
    .wrap-video-url {
      border-bottom: solid $border_size $border_color;
    }
    .label {
      color: $secondary_text_color;
    }
    .wrap-recommend-share,
    .wrap-group-name-input {
      position: relative;
      background: $primary_bg_color;
      border-radius: 3px;
      .wrap-close-icon {
        position: absolute;
        top: 12px;
        right: 12px;
      }
    }

    .wasted-time {
      color: $secondary_text_color;
    }
    // .wrap-request-reschedule {
    //   span {
    //     color: $secondary_text_color;
    //   }
    // }
    // .wrap-send-schedule {
    //   span {
    //     color: $active_color;
    //   }
    // }
    .wrap-recommend-sign-up {
      .cta-btn {
        background: $active_color;
        border-radius: 3px;
        color: #fff;
      }
    }
  }
}
</style>

<script>
import database from '@/database'
import {
  generateAddToGoogleCalendarUrl,
  generateAddToYahooCalendarUrl,
  generateAddToOutlookCalendarUrl,
  generateIcsUrl
} from '@/components/utils/calendar'
import { createCalendarEvent } from '@/components/utils/google'
import format from 'date-fns/format'
import formatDistance from 'date-fns/formatDistance'
import ja from 'date-fns/locale/ja'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import ModalWindow from '@/components/shared/ModalWindow'
import ItemCopyInvitationURL from '@/components/item/ItemCopyInvitationURL'
import ItemMeetingMember from '@/components/item/ItemMeetingMember'
import ItemMeetingToDo from '@/components/item/ItemMeetingToDo'
import ItemMeetingHistory from '@/components/item/ItemMeetingHistory'
import { getOnlineMeetingJoinUrl } from '../utils/urls'
// import { requestReschedule } from '@/services/functions'

export default {
  components: {
    ModalWindow,
    ItemCopyInvitationURL,
    ItemMeetingMember,
    ItemMeetingToDo,
    ItemMeetingHistory
  },
  computed: {
    ...mapStateAuth(['uid', 'isAnonymous']),
    addToGoogleCalendarUrl() {
      return generateAddToGoogleCalendarUrl(this.meeting)
    },
    addToYahooCalendarUrl() {
      return generateAddToYahooCalendarUrl(this.meeting)
    },
    addToOutlookCalendarUrl() {
      return generateAddToOutlookCalendarUrl(this.meeting)
    },
    icsUrl() {
      return generateIcsUrl(this.meeting)
    },
    addToCalendars() {
      return [
        {
          label: 'Google Calendar',
          img: '/icons/google_calendar.png',
          href: this.addToGoogleCalendarUrl
        },
        {
          label: 'Yahoo Calendar',
          img: '/icons/yahoo_calendar.png',
          href: this.addToYahooCalendarUrl
        },
        {
          label: 'Outlook Calendar',
          img: '/icons/outlook_calendar.png',
          href: this.addToOutlookCalendarUrl
          // href: this.icsUrl,
          // download: this.meeting.title
        },
        {
          label: 'Apple Calendar',
          img: '/icons/apple_calendar.png',
          href: this.icsUrl,
          download: this.meeting.title
        }
      ]
    },
    meetingUrl() {
      return getOnlineMeetingJoinUrl(this.meeting)
    },
    policyRequiresAdjustment() {
      return (
        ['01-unanimous'].includes(this.meeting.adjustmentPolicy) ||
        this.meeting.adjustmentPolicy === undefined
      ) // for old version
    }
  },
  props: {
    meeting: {
      type: Object,
      required: true
    },
    histories: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      testVal: 5,
      showRecommendShare: false,
      showAddToCalendar: false,
      showGroupNameInput: false,
      groupName: '',
      statusText: '',
      statusColor: '',
      conditionText: '',
      adjustedTextStr: null,
      showNowLoading: false,
      memberIsSavedAsGroup: false,
      emailAsInvitee: null,
      responsed: true,
      notification: null,
      wastedTime: null,
      attendeeWithoutAnyContact: []
    }
  },
  watch: {
    meeting(newMeeting, oldMeeting) {
      this.init()

      if (newMeeting.status === 'adjusted' && oldMeeting.status === 'adjusting') {
        this.notification = {
          label: '🎉 ミーティング日時が決定しました！',
          class: 'complete'
        }
      }

      if (newMeeting.status === 'adjusting' && oldMeeting.status === 'adjusted') {
        this.notification = {
          label: '🗓 日程が再調整になりました',
          class: 'reschedule'
        }
      }
    }
  },
  async created() {
    this.init()
  },
  methods: {
    async onAddMeetingToGoogleCalendar() {
      const event = await createCalendarEvent(this.meeting)
      console.log('Should save event', event)
    },
    async init() {
      const meeting = this.meeting
      if (meeting.status === 'adjusted') {
        this.adjustedTextStr = `${format(
          meeting.adjustedDate.start.toDate(),
          'yyyy/MM/dd HH:mm'
        )} ~ ${format(meeting.adjustedDate.end.toDate(), 'HH:mm')}`
      } else if (meeting.status === 'failed') {
        if (!meeting.candidateSlots[0]) {
          this.adjustedTextStr = '条件に合う日時がありませんでした。再調整してください。'
        } else {
          // 候補日があるならおすすめしてもいいかも
          this.adjustedTextStr = '条件に合う日時がありませんでした。再調整してください。'
        }
      }

      if (meeting.status === 'adjusting') {
        this.statusText = '調整中'
        this.statusColor = '#1DC1AC'

        // 作成直後で招待URLをシェアする必要があればポップアップを出す
        if (this.$route.name === 'new-meeting') {
          this.attendeeWithoutAnyContact = meeting.attendee.filter((e) => !(e.email || e.telNo))
          if (this.attendeeWithoutAnyContact[0]) {
            this.showRecommendShare = true
          }
        }
        // // 作成した直後だった場合はシェアのリコメンドを出す
        // const gapFromCreated = new Date () - meeting.createdAt.toDate()
        // if (meeting.createdBy === this.uid && gapFromCreated < 1000 * 300) { // 300秒(5分)以内
        //   this.showRecommendShare = true
        // }
      } else if (meeting.status === 'adjusted') {
        this.statusText = '調整完了'
        this.statusColor = '#4285f4'

        if (this.policyRequiresAdjustment) {
          const latestAttendeeEvent = await database
            .meetingCollection()
            .getLatestAttendeeEvents(this.$route.params.meetingId)
          const adjustedAt = latestAttendeeEvent.createdAt.toDate()
          this.wastedTime = formatDistance(adjustedAt, this.meeting.createdAt.toDate(), {
            locale: ja
          })
        }
      } else if (meeting.status === 'failed') {
        this.statusText = '調整失敗'
        this.statusColor = '#ff5252'
      }

      const groupCreatedInMeeting = await database
        .groupCollection(this.uid)
        .getGroupByMeetingId(this.meeting.id)
      if (groupCreatedInMeeting) this.memberIsSavedAsGroup = true

      // ----
      // let dayOfWeeksLabel
      // if (this.policyRequiresAdjustment) {
      //   const dayOfWeeks = meeting.dayOfWeek.map((e) => {
      //     if (e === 'Sunday') return '日'
      //     if (e === 'Monday') return '月'
      //     if (e === 'Tuesday') return '火'
      //     if (e === 'Wednesday') return '水'
      //     if (e === 'Thursday') return '木'
      //     if (e === 'Friday') return '金'
      //     if (e === 'Saturday') return '土'
      //   })
      //   dayOfWeeksLabel = dayOfWeeks.join('/')
      //   dayOfWeeksLabel = dayOfWeeksLabel === '月/火/水/木/金' ? '平日' : `${dayOfWeeksLabel}曜日の`
      // }

      // if (this.policyRequiresAdjustment) {
      //   const startDate = format(meeting.dateRange.start.toDate(), 'MM/dd')
      //   const endDate = format(meeting.dateRange.end.toDate(), 'MM/dd')
      //   this.conditionText =
      //     `${startDate}から${endDate}の間で、` +
      //     `${dayOfWeeksLabel}` +
      //     `${meeting.timeRange.start}~${meeting.timeRange.end}の内、` +
      //     `${meeting.duration}分のミーティングが可能な時間帯を調整`
      // }
      // ----

      // if (this.uid) {
      //   const user = await database.userCollection().findById(this.uid)
      //   // 招待された時のemailがログインのemailと異なることがある
      //   let emailAsInvitee = this.meeting.attendeeEmail.filter((e) => e == user.email)[0]
      //   if (!emailAsInvitee) emailAsInvitee = user.email
      //   this.emailAsInvitee = emailAsInvitee
      // }
      // if (this.$route.params.inviteeEmail) this.emailAsInvitee = this.$route.params.inviteeEmail

      // const attendee = meeting.attendee.filter((e) => e.email === this.emailAsInvitee)[0]
      // this.responsed = attendee.responsed
    },
    onAddGroup() {
      this.groupName = this.meeting.attendee.map((a) => a.name).join(', ')
      this.showGroupNameInput = true
    },
    async addGroup() {
      // const defaultGroupName = this.meeting.attendee.map((a) => a.name).join(', ')
      // const groupName = prompt('グループ名を入力', defaultGroupName)
      // if (!groupName) {
      //   alert('グループ名は必須です')
      //   this.addGroup()
      //   return
      // }

      if (!this.groupName) {
        alert('グループ名は必須です')
        this.addGroup()
        return
      }

      const user = await database.userCollection().findById(this.uid)
      const member = this.meeting.attendee
        .map((a) => {
          return { name: a.name, email: a.email, telNo: a.telNo }
        })
        .filter((a) => {
          return a.email !== user.email
        })
      const groupObj = {
        name: this.groupName,
        member,
        meetingId: this.meeting.id,
        createdBy: this.uid,
        createdAt: new Date(),
        updatedAt: new Date()
      }
      await database.groupCollection(this.uid).add(groupObj)
      this.memberIsSavedAsGroup = true

      this.$root.$emit('showSharedMessage', {
        message: 'グループが登録されました！',
        icon: 'mdi-account-multiple'
      })

      this.showGroupNameInput = false
    },
    setNotification(notification) {
      this.notification = notification
    },
    // onSend() {
    //   const path =
    //     this.uid === this.meeting.createdBy
    //       ? `/create-meeting/send-attendee-events/${this.$route.params.meetingId}/manual`
    //       : `/invitation/${this.$route.params.meetingId}/${this.emailAsInvitee}`
    //   this.$router.push(path)
    // }
    onCopyVideoUrl() {
      alert('ビデオ会議URLをコピーしました')
    },
    onGettingStarted() {
      this.$root.$emit('sendTrackingEventByClick', {
        message: 'click_start_app',
        option: { meetingId: this.meeting.id }
      })
      this.$router.push('/sign-up')
    }
  }
}
</script>
