<template lang="pug">
  Auth(@loggedIn="onLoggedIn")
    Header(:content="headerContent")
    NowLoading(:show="showNowLoading")
    div.wrap-meeting.f.fh
      ModuleMeeting(v-if="meeting" :meeting="meeting", :histories="meetingHistories || []")
</template>

<style lang="scss" scoped>
.wrap-meeting {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
import { requestReschedule } from '@/services/functions'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import Header from '@/components/shared/Header.vue'
import NowLoading from '@/components/shared/NowLoading'
import ModuleMeeting from '@/components/module/ModuleMeeting'

export default {
  components: {
    Auth,
    Header,
    NowLoading,
    ModuleMeeting
  },
  data() {
    return {
      headerContent: {
        title: 'ミーティング詳細',
        left: {
          icon: 'mdi-chevron-left',
          action: this.onBack
        }
      },
      showNowLoading: false,
      meeting: null,
      meetingHistories: null
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created() {
    // this.meeting = await database.meetingCollection()
    //   .findById(this.$route.params.meetingId)

    database
      .meetingCollection()
      .getMeetingInRealTime(this.$route.params.meetingId, this.updateMeeting)
    database
      .meetingCollection()
      .getMeetingHistoriesInRealTime(this.$route.params.meetingId, this.updateMeetingHistories)
  },
  methods: {
    async updateMeeting(d) {
      this.meeting = { ...d.data(), id: d.id }

      if (this.meeting.status === 'adjusted') {
        this.headerContent = {
          title: 'ミーティング詳細',
          left: {
            icon: 'mdi-chevron-left',
            action: this.onBack
          },
          rightActions: {
            icon: 'mdi-dots-vertical',
            actions: [
              {
                label: '再調整を依頼する',
                action: this.onRequestReschedule
              }
            ]
          }
        }
      } else if (this.meeting.status === 'adjusting') {
        if (this.uid) {
          const user = await database.userCollection().findById(this.uid)
          // 招待された時のemailがログインのemailと異なることがある
          let emailAsInvitee = this.meeting.attendeeEmail.filter((e) => e == user.email)[0]
          if (!emailAsInvitee) emailAsInvitee = user.email
          this.emailAsInvitee = emailAsInvitee
        }
        if (this.$route.params.inviteeEmail) this.emailAsInvitee = this.$route.params.inviteeEmail
        const attendee = this.meeting.attendee.filter((e) => e.email === this.emailAsInvitee)[0]
        if (!attendee.responsed) {
          this.headerContent = {
            title: 'ミーティング詳細',
            left: {
              icon: 'mdi-chevron-left',
              action: this.onBack
            },
            rightActions: {
              icon: 'mdi-dots-vertical',
              actions: [
                {
                  label: '日時を送信する',
                  action: this.onSendDate
                }
              ]
            }
          }
        }
      }
    },
    updateMeetingHistories(q) {
      this.meetingHistories = q.docs.map((doc) => {
        return {
          ...doc.data(),
          historyId: doc.id
        }
      })
    },
    onLoggedIn() {},
    onBack() {
      let path = '/home'
      if (this.$route.name === 'inviter-meeting') {
        if (this.$route.params.filter) {
          path = `/home/${this.$route.params.filter}`
        }
      }
      if (this.$route.name === 'calendar-meeting') {
        path = '/calendar'
      }
      if (!this.uid) path = '/sign-in'
      this.$router.push(path)
    },
    async onRequestReschedule() {
      const go = confirm('参加者全員に日程の再調整を依頼します')
      if (!go) return
      this.showNowLoading = true

      let user
      if (this.uid && !this.isAnonymous) {
        user = await database.userCollection().findById(this.uid)
      }

      // ログインしている状態でホームからきた時は this.$route.params.attendeeEmail がない
      let attendeeEmail = this.$route.params.inviteeEmail
        ? this.$route.params.inviteeEmail
        : user.email

      // 招待された時のemailがログインした時のemailと異なる可能性がある
      if (this.uid && !this.isAnonymous) {
        if (user.emailAsInvitee) {
          const emailAsInviteeInThisMeeting = this.meeting.attendeeEmail.filter(
            (e) => e == user.emailAsInvitee
          )[0]
          if (emailAsInviteeInThisMeeting) attendeeEmail = user.emailAsInvitee
        }
      }

      // ログインしていない場合はURLのemailを使う
      if (!this.uid) attendeeEmail = this.$route.params.inviteeEmail

      const { data: result } = await requestReschedule({
        meetingId: this.$route.params.meetingId,
        attendeeEmail
      })
      console.log('result:', result)

      this.showNowLoading = false
      alert('ミーティングをリスケジュールしました。改めて日程を送信します')

      const path =
        this.uid === this.meeting.createdBy
          ? `/create-meeting/send-attendee-events/${this.$route.params.meetingId}/manual`
          : `/invitation/reschedule/${this.$route.params.meetingId}/${attendeeEmail}`
      this.$router.push(path)
      // this.$router.push(`/invitation/reschedule/${this.$route.params.meetingId}/${attendeeEmail}`)
    },
    onSendDate() {
      const path =
        this.uid === this.meeting.createdBy
          ? `/create-meeting/send-attendee-events/${this.$route.params.meetingId}/manual`
          : `/invitation/${this.$route.params.meetingId}/${this.emailAsInvitee}`
      this.$router.push(path)
    }
  }
}
</script>
