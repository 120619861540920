import { clientId, config, discoveryDocs, scopes } from './config'
import { loadScript } from './script'
import { auth } from '@/components/utils/firebase'
import { format } from 'date-fns'

export async function loadGapiLibrary() {
  if (window.gapi) {
    // Refresh the token to make sure it's not expired
    await auth.currentUser.getIdToken()
    return window.gapi
  }

  return new Promise((resolve) => {
    loadScript('https://apis.google.com/js/api.js').then(() => {
      console.log('gapi: script loaded')
      const gapi = window.gapi

      console.log('gapi: loading auth client')
      gapi.load('client:auth2', async () => {
        console.log('gapi: auth client loaded, initializing')
        await gapi.client
          .init({
            apiKey: config.apiKey,
            clientId: clientId,
            discoveryDocs,
            scope: scopes.join(' ')
          })
          .catch((e) => console.error(e))
        console.log('gapi: initialized')

        // Refresh the token to make sure it's not expired
        await auth.currentUser.getIdToken()
        resolve(gapi)
      })
    })
  })
}

export async function createCalendarEvent(meeting) {
  return new Promise((resolve, reject) => {
    const { adjustedDate, title, about, location, attendee } = meeting

    // eslint-disable-next-line prettier/prettier
    const dateFormatStr = 'yyyy-MM-dd\'T\'HH:mm:ssX'
    var event = {
      summary: title,
      description: about,
      location,
      start: {
        dateTime: format(adjustedDate.start.toDate(), dateFormatStr, { timeZone: 'Asia/Tokyo' }),
        timeZone: 'Asia/Tokyo'
      },
      end: {
        dateTime: format(adjustedDate.end.toDate(), dateFormatStr, { timeZone: 'Asia/Tokyo' }),
        timeZone: 'Asia/Tokyo'
      },
      attendees: attendee.map(({ email }) => ({
        email
      })),
      reminders: {
        useDefault: true
      }
    }

    loadGapiLibrary().then((gapi) => {
      const request = gapi.client.calendar.events.insert({
        calendarId: 'primary',
        resource: event
      })
      request.execute((response) => {
        if (response.error) {
          reject(new Error(response.error.message))
          return
        }
        resolve(response)
      })
    })
  })
}

export async function listCalendarEvent() {
  const gapi = await loadGapiLibrary()
  console.log('listing calendar events')
  const data = await gapi.client.calendar.events
    .list({
      calendarId: 'primary',
      timeMin: new Date().toISOString(),
      showDeleted: false,
      singleEvents: true,
      maxResults: 10,
      orderBy: 'startTime'
    })
    .then((response) => {
      return response.result
    })
  console.log('response', data)
}
