<template lang="pug">
div.wrap-item-meeting-to-do
  AtomToDoList(v-for="task in tasks" :task="task")
</template>

<style lang="scss" scoped>
.wrap-item-meeting-to-do {
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import AtomToDoList from '@/components/atom/AtomToDoList'

export default {
  components: {
    AtomToDoList
  },
  props: {
    meeting: {
      type: Object,
      required: true
    },
    histories: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tasks: []
    }
  },
  watch: {
    meeting: {
      handler() {
        this.updateTasks()
      },
      deep: true
    },
    histories: {
      handler() {
        this.updateTasks()
      },
      deep: true
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created() {
    this.updateTasks()
  },
  methods: {
    updateTasks() {
      console.log('updateTasks')
      this.tasks = []
      const attendees = this.meeting.attendee

      const historyCreated = this.histories.filter((h) => h.type === 'created')[0]
      const inviterAttendee = attendees.filter((a) => a.email === historyCreated.attendeeEmail)[0]
      const initialTask = {
        avatarName: inviterAttendee.name,
        text: 'ミーティングを作成する',
        doneAt: historyCreated.createdAt,
        done: true
      }
      this.tasks.push(initialTask)

      const historiesShared = this.histories.filter((h) => h.type === 'schedule_shared')
      const sharedContacts = historiesShared.map((h) => {
        let contact
        if (h.name) contact = h.name
        if (h.telNo) contact = h.telNo
        if (h.attendeeEmail) contact = h.attendeeEmail
        return contact
      })
      const inputTasks = attendees.map((a) => {
        let contact
        if (a.name) contact = a.name
        if (a.telNo) contact = a.telNo
        if (a.email) contact = a.email
        const done = sharedContacts.includes(contact)
        let task = {
          avatarName: a.name,
          text: `${a.name}が日程を入力する`,
          done
        }
        if (done) {
          const history = historiesShared.filter((h) => {
            if (h.name) return contact === h.name
            if (h.telNo) return contact === h.telNo
            if (h.attendeeEmail) return contact === h.attendeeEmail
          })[0]
          return { ...task, doneAt: history.createdAt }
        } else {
          return task
        }
      })
      this.tasks = this.tasks.concat(inputTasks)

      // 全員が入力していたら
      let taskScheduleByAI = {
        avatarName: 'AI',
        text: 'AIがみんなの日程を参照して日時を決定する',
        done: this.meeting.status === 'adjusted' ? true : false
      }
      if (this.meeting.status === 'adjusted') {
        taskScheduleByAI.doneAt = this.meeting.adjustedAt
      }
      this.tasks.push(taskScheduleByAI)
    }
  }
}
</script>
