<template lang="pug">
  div.wrap-item-history.f.fm.flex-between.py6
    div.profile.f.fm
      div.mr12.avatar
        div(v-if="textIcon") {{textIcon}}
        //- v-icon(v-else, size="38px") mdi-account-circle
        AtomUserIconByEmail(v-else :email="history.attendeeEmail" :size="38")
      div.pt6
        span.fz14.block(v-html="message")
        span.email.fz12.block {{createdAt}}
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-item-history {
  border-bottom: solid $border_size $border_color;
  .profile {
    .avatar {
      width: 40px;
      font-size: 38px;
    }
    .email {
      color: $secondary_text_color;
    }
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import format from 'date-fns/format'
import AtomUserIconByEmail from '@/components/atom/AtomUserIconByEmail'

export default {
  name: 'ItemMeetingHistory',
  components: {
    AtomUserIconByEmail
  },
  props: {
    history: {
      type: Object,
      required: true
    },
    attendees: {
      type: Array,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapStateAuth(['uid']),
    textIcon() {
      let icon = null

      switch (this.history.type) {
        case 'failed':
          icon = '😔'
          break

        case 'adjusted':
          icon = '🎉'
          break
      }

      return icon
    },
    avatarIcon() {
      return null
    },
    message() {
      const attendee = this.history.attendeeEmail
        ? this.attendees.find((v) => v.email === this.history.attendeeEmail)
        : null
      const attendeeName = attendee ? attendee.name : undefined
      let message = null

      switch (this.history.type) {
        case 'created':
          message = `<b>${attendeeName}</b>がミーティングを作成しました`
          break

        case 'readjusted':
          message = `<b>${attendeeName}</b>が再調整を依頼しました`
          break

        case 'failed':
          message = '<b>日程調整が失敗しました</b>'
          break

        case 'adjusted':
          message = '<b>日程調整が完了しました！</b>'
          break

        case 'schedule_shared':
          message = `<b>${attendeeName}</b>が日時の入力を完了しました`
          break
      }

      return message
    },
    createdAt() {
      return format(this.history.createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')
    }
  },
  methods: {}
}
</script>
