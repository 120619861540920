<template lang="pug">
div.wrap-atom-to-do-list.py6
  div.to-do-content.f.fm
    div.f.fm.mr6
      v-icon(v-if="task.done"
        :color="pallete.$active_color" 
        size="40") mdi-checkbox-marked-circle
      v-icon(v-else size="40") mdi-checkbox-blank-circle-outline
    div
      span(:class="{'is-done': task.done}").fz14.block.mb2 {{task.text}}
      span(v-if="task.done").about.fz12.block {{task.avatarName}}が{{doneAtStr}}に完了
    //- AtomUserIconByEmail(:email="task.avatarEmail")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
.wrap-atom-to-do-list {
  border-bottom: $basic_border;
  .to-do-content {
    .is-done {
      text-decoration: line-through;
    }
    .about {
      color: $secondary_text_color;
    }
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import { formatDistanceToNow } from 'date-fns'
import ja from 'date-fns/locale/ja'
import AtomUserIconByEmail from '@/components/atom/AtomUserIconByEmail'

export default {
  components: {
    AtomUserIconByEmail
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      defaultIconPath: '/img/default-icon.png'
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    doneAtStr() {
      if (this.task.done) {
        const option = {
          addSuffix: true,
          locale: ja
        }
        return formatDistanceToNow(this.task.doneAt.toDate(), option)
      } else {
        return ''
      }
    }
  },
  async created() {},
  methods: {}
}
</script>
