<template lang="pug">
  div.wrap-atom-user-icon-by-email
    div(v-if="user").mx3.my3
      v-avatar(:size="`${size}px`")
        v-img(:src="user.iconURL")
    div(v-else)
      v-icon(:size="`${size + 6}px`") mdi-account-circle
</template>

<style lang="scss" scoped>
.wrap-atom-user-icon-by-email {
}
</style>

<script>
import database from '@/database'

export default {
  props: {
    email: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      default: 38
    }
  },
  data() {
    return {
      user: null
    }
  },
  async created() {
    this.user = await database.userCollection().getUserByEmail(this.email)
  }
}
</script>
